import React, { useState } from "react";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";
import axios from "axios";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../config/config";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const { lang } = useLangContext();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      icon: "info",
      title: lang === "tr" ? "Bilgi" : "Info",
      text:
        lang === "tr"
          ? "Form gönderiliyor, lütfen bekleyiniz..."
          : "Submitting form, please wait...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });

    try {
      const response = await axios.post(
        `${API_BASE_URL}contact-form`,
        formData
      );

      if (response.data.success) {
        Swal.close();

        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Form başarıyla gönderildi!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            response.data.message ||
            "Formu gönderirken bir hata oluştu, bir süre sonra tekrar deneyiniz.",
        });
      }
    } catch (error) {
      console.error("Error sending data:", error);

      // Show error message
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Formu gönderirken bir hata oluştu, bir süre sonra tekrar deneyiniz.",
      });
    }
  };

  return (
    <div className="ltn__contact-message-area mb-28 mb--80 md:mt-24 mt-8">
      <div className="md:px-40 px-10">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2 ">
                {lang === "tr"
                  ? jsonTr.BizimleİletişimeGeçin
                  : jsonEn.BizimleİletişimeGeçin}
              </h4>

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        placeholder={
                          lang === "tr"
                            ? jsonTr.Adınızıgiriniz
                            : jsonEn.Adınızıgiriniz
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        placeholder={
                          lang === "tr"
                            ? jsonTr.Mailadresinizigiriniz
                            : jsonEn.Mailadresinizigiriniz
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        placeholder={
                          lang === "tr"
                            ? jsonTr.Telefonnumaranızıgiriniz
                            : jsonEn.Telefonnumaranızıgiriniz
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="text"
                        name="subject"
                        onChange={handleChange}
                        placeholder={
                          lang === "tr"
                            ? jsonTr.Konuyugiriniz
                            : jsonEn.Konuyugiriniz
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    className="p-2"
                    name="message"
                    onChange={handleChange}
                    placeholder={
                      lang === "tr"
                        ? jsonTr.Mesajınızıyazınız
                        : jsonEn.Mesajınızıyazınız
                    }
                    defaultValue={""}
                  />
                </div>

                <div className="btn-wrapper mt-2">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase bg-[#ae8d2f]"
                    type="submit"
                  >
                    {lang === "tr" ? jsonTr.Gönder : jsonEn.Gönder}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
