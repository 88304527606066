import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import HomeSlider from "../components/homeslider";
import MobileSlider from "../components/mobileslider";

const Home_V1 = () => {
  return (
    <div>
      <Navbar />
      <HomeSlider className="md:hidden block" />
      <MobileSlider className="" />
      <Footer />
    </div>
  );
};

export default Home_V1;
