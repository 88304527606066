import React from "react";
import { Envelope, MapPin, Phone } from "@phosphor-icons/react";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";

const ContactInfo = ({ contactData }) => {
  const { lang, updateLang } = useLangContext();
  const formatContent = (data) => {
    if (Array.isArray(data)) {
      return data.join("<br />");
    } else {
      return data;
    }
  };
  return (
    <div className="ltncontact-address-area mb-50 mt-28">
      <div className="container">
        <div className="row mb-4">
          {contactData.email && (
            <ContactItem
              icon={<Envelope size={50} weight="fill" color="#ae8d2f" />}
              title={lang === "tr" ? jsonTr.Email : jsonEn.Email}
              content={formatContent(Object.values(contactData.email))}
            />
          )}
          {contactData.phone && (
            <ContactItem
              icon={<Phone size={50} weight="fill" color="#ae8d2f" />}
              title={
                lang === "tr" ? jsonTr.TelefonNumarası : jsonEn.TelefonNumarası
              }
              content={formatContent(Object.values(contactData.phone))}
            />
          )}
          {contactData.address && (
            <ContactItem
              icon={<MapPin size={50} weight="fill" color="#ae8d2f" />}
              title={lang === "tr" ? jsonTr.Adres : jsonEn.Adres}
              content={contactData.address}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ContactItem = ({ icon, title, content }) => {
  return (
    <div className="col-lg-4">
      <div className="ltncontact-address-item ltncontact-address-item-3 mx-8 box-shadow p-8 text-center mb-4">
        <div className="inline-block mb-2">{icon}</div>
        <h3 className="font-bold text-xl mb-2">{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default ContactInfo;
