import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import AboutV4 from "../components/kvkk";

const Service_V1 = () => {
  return (
    <div>
      <Navbar />
      <AboutV4 />
      <Footer />
    </div>
  );
};

export default Service_V1;
