import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Anasayfa from "./pages/anasayfa";
import "./input.css";
import Hakkımızda from "./pages/hakkimizda";
import Galeri from "./pages/galeri";
import Ürünler from "./pages/ürünlerimiz";
import Referanslar from "./pages/referanslarimiz";
import İletişim from "./pages/iletisim";
import Kvkk from "./pages/kvkk";
import Fiyat from "./pages/fiyatlarimiz";
import LanguageContextProvider from "./context/LangContext";
import "./service/i18n";
import NotFoundPage from "./pages/not-found";

const Root = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Anasayfa />} />
        <Route path="/hakkimizda" element={<Hakkımızda />} />
        <Route path="/galeri" element={<Galeri />} />
        <Route path="/ürünlerimiz" element={<Ürünler />} />
        <Route path="/referanslarimiz" element={<Referanslar />} />
        <Route path="/iletisim" element={<İletişim />} />
        <Route path="/kvkk" element={<Kvkk />} />
        <Route path="/fiyatlarimiz" element={<Fiyat />} />
        <Route path="/404" element={<NotFoundPage status={404} />} />
        <Route path="*" element={<Navigate status={404} to={"/404"} />} />
      </Routes>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <LanguageContextProvider>
      <Root />
    </LanguageContextProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
