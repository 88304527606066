import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../config/config";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";

const Guncelfiyat = () => {
  const [products, setProducts] = useState([]);
  const { lang, updateLang } = useLangContext();

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}product-card`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setProducts(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductsData();
  }, []);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mt-32 mb-40	">
        <div className="md:px-40 px-10">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content ">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <h1 className="section-title d-flex justify-content-center mb-5">
                    {lang === "tr" ? jsonTr.demirfiyat : jsonEn.demirfiyat}
                    <span>.</span>
                  </h1>
                  <div className="table-responsive ">
                    <table className="table table-striped table-hover p-3 border border-dark rounded-end">
                      <thead>
                        <tr className="bg-dark " style={{ color: "#ae8d2f" }}>
                          <th>{lang === "tr" ? jsonTr.demirsehir : jsonEn.demirsehir}</th>
                          <th>Q8 mm</th>
                          <th>Q10 mm</th>
                          <th>Q12 - Q32 mm</th>
                          <th>{lang === "tr" ? jsonTr.guncellenmetarihi : jsonEn.guncellenmetarihi}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((data) => (
                          <tr>
                            <td className="border-end fw-bold border-dark">
                              {data.productArea}
                            </td>
                            <td className="border-end border-dark">
                              {data.sekizmm + " " + "₺"}
                            </td>
                            <td className="border-end border-dark">
                              {data.onmm + " " + "₺"}
                            </td>
                            <td className="border-end border-dark">
                              {data.onikimm + " " + "₺"}
                            </td>
                            <td className="border-end border-dark">
                              {new Date(data.updated_at).toLocaleDateString(
                                "tr-TR"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guncelfiyat;
