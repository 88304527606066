import React from "react";

const Social = () => {
  return (
    <div className="ltn__social-media">
      <ul>
        <li>
          <a href="https://www.facebook.com" title="Facebook" target="_blank">
            <i className="fab fa-facebook-f" />
          </a>
        </li>
        <li>
          <a href="https://www.twitter.com" title="Twitter" target="_blank">
            <i className="fab fa-twitter" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com" title="Instagram" target="_blank">
            <i className="fab fa-instagram" />
          </a>
        </li>
        <li>
          <a href="https://www.hankar.com.tr" title="Dribbble" target="_blank">
            <i className="fab fa-dribbble" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
