import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SponsorV2 from "../components/referans";

const BlogGridPage = () => {
  return (
    <div>
      <Navbar />
      <SponsorV2 />
      <Footer />
    </div>
  );
};

export default BlogGridPage;
