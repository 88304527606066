import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { API_BASE_URL, API_FILE_URL } from "../config/config";
import { useLangContext } from "../context/LangContext";
import Table from "./table";

const HomeSlider = () => {
  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { lang } = useLangContext();

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}home-slider`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        const filteredData = data.message.filter(
          (slide) => slide.name === "web"
        );

        setHomeData(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchContactData();
  }, [lang]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div style={{ position: "relative" }}>
        <Swiper
          cssMode={true}
          navigation={true}
          style={{
            "--swiper-navigation-color": "#FFF",
            "--swiper-pagination-color": "#FFF",
          }}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper hidden md:block"
        >
          {homeData.map((slide, index) => (
            <SwiperSlide key={index} className="relative">
              <img
                src={`${API_FILE_URL}assets/images/homepage/${slide.image}`}
                alt={`Slide ${index + 1}`}
                loading="lazy"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div style={{ position: "absolute", bottom: 0, right: 0, zIndex: 999 }}>
          <div className="hidden md:block">
            <Table />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeSlider;
