import React, { useEffect, useState } from "react";
import { FileArrowDown } from "@phosphor-icons/react";
import { useLangContext } from "../context/LangContext";
import { API_BASE_URL, API_FILE_URL } from "../config/config";

const AboutV4 = () => {
  const { lang, updateLang } = useLangContext();
  const [data, setData] = useState({
    contract: {},
    contractPdf: [],
  });

  useEffect(() => {
    const fetchKvkkData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${lang}/kvkk`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setData(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchKvkkData();
  }, [lang]);

  const handleDownloadClick = (kvkkFormsUrl, filename) => {
    const link = document.createElement("a");
    link.href = kvkkFormsUrl;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="ltn__about-us-area pt-120--- pb-90 go-top mt-24 md:mt-32">
      <div className="md:px-40 px-10">
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div className="about-us-info-wrap">
              <div className=" ltn__section-title-2--- mb-20">
                <h1 className="section-title">
                  {data.contract.contractTitle}
                  <span>.</span>
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.contract.contractDetail,
                  }}
                ></p>
              </div>

              {data.contractPdf.map((pdf, index) => (
                <div
                  key={index}
                  className="ltn__callout mt-28 flex bg-[#d1d1d1] py-4 px-4 justify-between items-center"
                >
                  <button
                    onClick={() =>
                      handleDownloadClick(
                        `${API_FILE_URL}assets/pdf/contract/${pdf.contractPdf}`,
                        pdf.contractPdf
                      )
                    }
                  >
                    {pdf.contractPdfTitle}
                  </button>
                  <FileArrowDown size={32} weight="fill" color="#ae8d2f" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV4;
