import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Social from "./social";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";

const Navbar = () => {
  const { lang, updateLang } = useLangContext();

  useEffect(() => {}, [lang]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-top-area section-bg-6 top-area-color-white--- hidden md:block">
          <div className="px-6 ">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:info@hankar.com.tr">
                        <i className="icon-mail" /> info@hankar.com.tr
                      </a>
                    </li>
                    <li>
                      <a href="tel:+90 532 612 65 06">
                        <i className="icon-placeholder" /> +90 532 612 65 06
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li className="mt-2 mb-2">
                        <Social />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__header-middle-area ltn__header-sticky  bg-black">
          <div className="container">
            <div className="row">
              <div className="col md:block hidden">
                <div className="site-logo-wrap">
                  <div className="site-logo go-top ">
                    <Link to="/">
                      <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu go-top">
                      <ul>
                        <li className="bg-gradient-to-r from-primary to-danger bg-clip-text text-transparent">
                          <Link to="/">
                            {lang === "tr" ? jsonTr.Anasayfa : jsonEn.Anasayfa}
                          </Link>
                        </li>
                        <li>
                          <Link to="/hakkimizda">
                            {lang === "tr" ? jsonTr.Kurumsal : jsonEn.Kurumsal}
                          </Link>
                          <ul>
                            <li>
                              <Link to="/hakkimizda">
                                {lang === "tr"
                                  ? jsonTr.Hakkımızda
                                  : jsonEn.Hakkımızda}
                              </Link>
                            </li>
                            <li>
                              <Link to="/kvkk">
                                {lang === "tr" ? jsonTr.KVKK : jsonEn.KVKK}
                              </Link>
                            </li>

                            <li>
                              <Link to="/galeri">
                                {lang === "tr" ? jsonTr.Galeri : jsonEn.Galeri}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/ürünlerimiz">
                            {lang === "tr"
                              ? jsonTr.Ürünlerimiz
                              : jsonEn.Ürünlerimiz}
                          </Link>
                        </li>
                        <li>
                          <Link to="/referanslarimiz">
                            {lang === "tr"
                              ? jsonTr.Referanslarımız
                              : jsonEn.Referanslarımız}
                          </Link>
                        </li>

                        <li>
                          <Link to="/iletisim">
                            {lang === "tr" ? jsonTr.İletişim : jsonEn.İletişim}
                          </Link>
                        </li>
                        <li>
                          <Link to="/fiyatlarimiz">
                            {lang === "tr"
                              ? jsonTr.Fiyatlarımız
                              : jsonEn.Fiyatlarımız}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col ltn__header-options ltn__header-options-2 mb-sm-20 md:block hidden">
                <div className="flex text-xl text-[#d1d1d1] font-sans font-bold space-x-2 ml-40">
                  <button
                    className={
                      updateLang === "tr"
                        ? "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#9b7719] font-semibold hover:text-white  px-3  rounded"
                        : "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#9b7719] font-semibold hover:text-white  px-3  rounded"
                    }
                    onClick={() => updateLang("tr")}
                  >
                    TR
                  </button>
                  <button
                    className={
                      updateLang === "en"
                        ? "bg-[#e2e2e2] hover:bg-[#247781] text-[#ae8d2f] font-semibold hover:text-white px-3 rounded py-2"
                        : "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#ae8d2f] font-semibold hover:text-white  px-3  rounded py-2"
                    }
                    onClick={() => updateLang("en")}
                  >
                    EN
                  </button>
                </div>
                {/* Mobile Menu Button */}
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className=" ltn__header-options ltn__header-options-2 mb-sm-5 md:hidden  ">
                <div className="site-logo ">
                  <Link to="/">
                    <img
                      className="w-44 h-12 "
                      src={publicUrl + "assets/img/logo.png"}
                      alt="Logo"
                    />
                  </Link>
                </div>

                <div className="flex text-xl text-[#d1d1d1] font-sans font-bold space-x-2  justify-between ml-14">
                  <button
                    className={
                      updateLang === "tr"
                        ? "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#9b7719] font-semibold hover:text-white  px-2  rounded"
                        : "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#9b7719] font-semibold hover:text-white  px-2  rounded"
                    }
                    onClick={() => updateLang("tr")}
                  >
                    TR
                  </button>
                  <button
                    className={
                      updateLang === "en"
                        ? "bg-[#e2e2e2] hover:bg-[#247781] text-[#ae8d2f] font-semibold hover:text-white px-2 rounded py-1"
                        : "bg-[#e2e2e2] hover:bg-[#ae8d2f] text-[#ae8d2f] font-semibold hover:text-white  px-2  rounded py-1"
                    }
                    onClick={() => updateLang("en")}
                  >
                    EN
                  </button>
                </div>
                {/* Mobile Menu Button */}
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="ltn__utilize-mobile-menu" className="ltn__utilize  bg-black ">
        <div className="ltn__utilize-menu-inner ltn__scrollbar ">
          <div className="ltn__utilize-menu-head ">
            <div className="site-logo ">
              <Link to="/">
                <img
                  className="w-44 h-12 "
                  src={publicUrl + "assets/img/logo.png"}
                  alt="Logo"
                />
              </Link>
            </div>
            <button className="ltn__utilize-close font-bold">×</button>
          </div>

          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <a href="/" className="border-b-2 ">
                  {lang === "tr" ? jsonTr.Anasayfa : jsonEn.Anasayfa}
                </a>
              </li>
              <li>
                <Link to="/hakkimizda" className="border-b-2 ">
                  {lang === "tr" ? jsonTr.Kurumsal : jsonEn.Kurumsal}
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hakkimizda">
                      {lang === "tr" ? jsonTr.Hakkımızda : jsonEn.Hakkımızda}
                    </Link>
                  </li>
                  <li>
                    <Link to="/kvkk">
                      {lang === "tr" ? jsonTr.KVKK : jsonEn.KVKK}
                    </Link>
                  </li>

                  <li>
                    <Link to="/galeri">
                      {lang === "tr" ? jsonTr.Galeri : jsonEn.Galeri}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/ürünlerimiz" className="border-b-2 ">
                  {lang === "tr" ? jsonTr.Ürünlerimiz : jsonEn.Ürünlerimiz}
                </Link>
              </li>
              <li>
                <Link to="/referanslarimiz" className="border-b-2 ">
                  {lang === "tr"
                    ? jsonTr.Referanslarımız
                    : jsonEn.Referanslarımız}
                </Link>
              </li>
              <li>
                <Link to="/iletisim" className="border-b-2 ">
                  {lang === "tr" ? jsonTr.İletişim : jsonEn.İletişim}
                </Link>
              </li>
              <li>
                <Link to="/fiyatlarimiz" className="border-b-2 ">
                  {lang === "tr" ? jsonTr.Fiyatlarımız : jsonEn.Fiyatlarımız}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
