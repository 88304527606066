import React, { useState } from "react";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";

const Table = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { lang, updateLang } = useLangContext();

  const handleClick = () => {
    setIsOpen(false);
    window.location.href = "/fiyatlarimiz";
  };
  return (
    <div>
      {isOpen && (
        <div className="popup-background  ">
          <div className="popup animate__animated animate__backInRight">
            <p
              onClick={handleClick}
              className="text-white fw-bold text-center p-2 fs-4 hover-link"
            >
              {lang === "tr" ? jsonTr.guncelfiyat : jsonEn.guncelfiyat}
              <i className="fa-solid fa-hand-point-right fs-1 mt-2"></i>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
