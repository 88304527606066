import React from "react";
import Navbar from "../components/navbar";
import AboutV4 from "../components/hakkimizda";
import Features from "../components/vizyon";
import Footer from "../components/footer";
const About_v1 = () => {
  return (
    <div>
      <Navbar />
      <AboutV4 />
      <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      <Footer />
    </div>
  );
};

export default About_v1;
