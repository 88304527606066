import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import ContactInfo from "../components/contact-info";
import ContactForm from "../components/contact-form";
import Footer from "../components/footer";
import Map from "../components/map";
import { API_BASE_URL } from "../config/config";

const ContactV1 = () => {
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}contact`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setContactData(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchContactData();
  }, []);

  return (
    <div>
      <Navbar />
      <ContactInfo contactData={contactData} />
      <Map contactData={contactData} />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactV1;
