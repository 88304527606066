import React, { useState, useEffect } from "react";
import { useLangContext } from "../context/LangContext";
import axios from "axios";
import { API_BASE_URL, API_FILE_URL } from "../config/config";

const SponsorV2 = () => {
  const { lang, updateLang } = useLangContext();
  const [referenceImages, setReferenceImages] = useState([]);
  useEffect(() => {
    const fetchReferenceImages = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}${lang}/referance`);

        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }

        setReferenceImages(response.data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchReferenceImages();
  }, [lang]);

  return (
    <div className="ltn__brand-logo-area ltn__brand-logo-1  mt-24">
      <div className="container-fluid">
        <div className="grid md:grid-cols-5 grid-cols-1 pb-24 md:px-24">
          {referenceImages.map((image, index) => (
            <div
              className="md:w-48 h-48 pb-6 mt-4 p-2 rounded-md border-2 border-[#d1d1d1d] flex justify-center items-center transform transition-transform hover:scale-110"
              key={index}
            >
              <div className="">
                <img
                  className="object-contain md:h-48 h-44"
                  src={`${API_FILE_URL}assets/images/referance/${image.referanceImage}`}
                  alt={`Brand Logo ${index + 1}`}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SponsorV2;
