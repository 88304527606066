import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";

const Footer_v1 = () => {
  const { lang, updateLang } = useLangContext();

  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5 pb-20">
        <div className=" px-3 md:px-8">
          <div className="row">
            <div className="col-xl-5 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                  </div>
                </div>

                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          Beytepe Mahallesi Sultan Süleyman Bulvarı 5314. Cadde,
                          A /Blok, No: 4/52 Çankaya/ANKARA
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-call" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel: +90 532 612 65 06">+90 532 612 65 06</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i className="icon-mail" />
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:info@hankar.com.tr">
                            info@hankar.com.tr
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">
                  {lang === "tr" ? jsonTr.Kurumsal : jsonEn.Kurumsal}
                </h4>
                <div className="footer-menu go-top ">
                  <ul>
                    <li>
                      <Link to="/hakkimizda">
                        {lang === "tr" ? jsonTr.Hakkımızda : jsonEn.Hakkımızda}
                      </Link>
                    </li>
                    <li>
                      <Link to="/kvkk">
                        {lang === "tr" ? jsonTr.KVKK : jsonEn.KVKK}
                      </Link>
                    </li>
                    <li>
                      <Link to="/galeri">
                        {lang === "tr" ? jsonTr.Galeri : jsonEn.Galeri}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">
                  {lang === "tr" ? jsonTr.Ürünlerimiz : jsonEn.Ürünlerimiz}
                </h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/ürünlerimiz">
                        {lang === "tr"
                          ? jsonTr.Ürünlerimiz
                          : jsonEn.Ürünlerimiz}
                      </Link>
                    </li>
                    <li>
                      <Link to="/referanslarimiz">
                        {lang === "tr"
                          ? jsonTr.Referanslarımız
                          : jsonEn.Referanslarımız}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">
                  {lang === "tr" ? jsonTr.İletişim : jsonEn.İletişim}
                </h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      <Link to="/iletisim">
                        {lang === "tr" ? jsonTr.İletişim : jsonEn.İletişim}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v1;
