import React, { useEffect, useState } from "react";
import { useLangContext } from "../context/LangContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearchPlus } from "@fortawesome/free-solid-svg-icons"; // Import the magnifying glass icon
import { API_BASE_URL, API_FILE_URL } from "../config/config";
import { MagnifyingGlass } from "@phosphor-icons/react";

const ShopGridV1 = () => {
  const { lang, updateLang } = useLangContext();
  const [galleryData, setGalleryData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${lang}/gallery`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setGalleryData(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductsData();
  }, [lang]);

  const handleImageClick = (index) => {
    setSelectedImage(galleryData[index]);
  };

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-100 mt-24 md:mt-32">
        <div className="md:px-36 px-10">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {galleryData.map((item, index) => (
                  <div
                    key={index.id}
                    className="col-lg-4 col-sm-6 col-12 cursor-pointer"
                    onClick={() => handleImageClick(index)}
                  >
                    <div className="ltn__product-item text-center overflow-hidden grayscale-hover ">
                      <div className="slider-content">
                        <img
                          className="w-full h-64 object-stretch slider-item mb-4"
                          src={`${API_FILE_URL}assets/images/gallery/${item.galleryImage}`}
                          alt={`Gallery Image ${index + 1}`}
                        />
                        <div className="zoom-icon bg-white py-2 px-2 rounded-full">
                          <MagnifyingGlass
                            size={30}
                            weight="bold"
                            color="#333"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Büyüme Modalı */}
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50 px-4">
          <div className="md:max-w-3xl max-h-3xl overflow-hidden bg-white rounded-lg p-1 relative">
            <img
              src={`${API_FILE_URL}assets/images/gallery/${selectedImage.galleryImage}`}
              alt="Büyük Resim"
              className="w-full md:h-96 object-contain"
            />
            <button
              className="absolute top-1 right-1 text-white bg-gray-900 px-2 py-1 rounded hover:bg-[#d1d1d1] hover:text-black"
              onClick={() => setSelectedImage(null)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopGridV1;
