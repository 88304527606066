import React from "react";
import Navbar from "../components/navbar";
import PortfolioV1 from "../components/galeri";
import Footer from "../components/footer";

const Portfolio_V1 = () => {
  return (
    <div>
      <Navbar />
      <PortfolioV1 />
      <Footer />
    </div>
  );
};

export default Portfolio_V1;
