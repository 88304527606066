import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";
import { API_BASE_URL } from "../config/config";

const FeaturesV1 = (props) => {
  let customClass = props.customClass ? props.customClass : "";
  const { lang, updateLang } = useLangContext();
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${lang}/about`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAboutData(data.message[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAboutData();
  }, [lang]);
  return (
    <div className={customClass}>
      <div className="md:px-28 px-10">
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon"></div>
              <div className="ltn__feature-info">
                <h3 className="font-bold text-xl md:text-2xl mb-2">
                  <Link to="/service-details ">
                    {lang === "tr" ? jsonTr.Vizyonumuz : jsonEn.Vizyonumuz}
                  </Link>
                </h3>
                <p>{aboutData.aboutVision}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
              <div className="ltn__feature-icon"></div>
              <div className="ltn__feature-info">
                <h3 className="font-bold text-xl md:text-2xl mb-2">
                  <Link to="/service-details">
                    {lang === "tr" ? jsonTr.Misyonumuz : jsonEn.Misyonumuz}
                  </Link>
                </h3>
                <p>{aboutData.aboutMission}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon"></div>
              <div className="ltn__feature-info">
                <h3 className="font-bold text-xl md:text-2xl mb-2">
                  <Link to="/service-details">
                    {lang === "tr" ? jsonTr.Hedefimiz : jsonEn.Hedefimiz}
                  </Link>
                </h3>
                <p>{aboutData.aboutTarget}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesV1;
