import React, { useState, useEffect } from "react";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";
import { API_BASE_URL } from "../config/config";

const AboutV4 = () => {
  const { lang, updateLang } = useLangContext();
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${lang}/about`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAboutData(data.message[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAboutData();
  }, [lang]);

  return (
    <div className="ltn__about-us-area pt-120--- pb-90  mt-24 md:mt-32 go-top ">
      <div className="md:px-28 px-10">
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div className="about-us-info-wrap">
              <div className=" ltn__section-title-2--- mb-20">
                <h1 className="section-title">
                  {aboutData.aboutTitle}
                  <span>.</span>
                </h1>
                <p className="mt-2">{aboutData.aboutDescription}</p>
              </div>

              <div className="ltn__callout bg-overlay-theme-05  mt-30">
                <p>"{lang === "tr" ? jsonTr.Söz : jsonEn.Söz}"</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutV4;
