import React from "react";

const Map = ({ contactData }) => {
  return (
    <div className="google-map">
      <iframe
        src={contactData.map}
        width="100%"
        height="100%"
        title="map"
        frameBorder={0}
        allowFullScreen
        aria-hidden="false"
        tabIndex={0}
      />
    </div>
  );
};

export default Map;
