import React from "react";
import Navbar from "../components/navbar";
import ShogGrid from "../components/ürün";
import Footer from "../components/footer";

const ShopGrid_V1 = () => {
  return (
    <div>
      <Navbar />
      <ShogGrid />
      <Footer />
    </div>
  );
};

export default ShopGrid_V1;
