import React, { useEffect, useState } from "react";
import { useLangContext } from "../context/LangContext";
import jsonTr from "../translations/tr/common.json";
import jsonEn from "../translations/en/common.json";
import { API_BASE_URL, API_FILE_URL } from "../config/config";

const ShopGridV1 = () => {
  const { lang, updateLang } = useLangContext();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}${lang}/product`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setProducts(data.message);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProductsData();
  }, [lang]);

  const filteredProducts = products.filter((product) =>
    product.productTitle.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-70 mt-32 min-h-screen	">
        <div className="md:px-40 px-10">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content ">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ltn__search-widget mb-30 mt -mt-20 ">
                          <form
                            action="#"
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <input
                              type="text"
                              name="search"
                              placeholder={
                                lang === "tr" ? jsonTr.Ürünadı : jsonEn.Ürünadı
                              }
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </form>
                        </div>
                      </div>
                      {filteredProducts.map((product) => (
                        <div
                          key={product.id}
                          className="col-lg-4 col-sm-6 col-12"
                        >
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center--- border rounded-xl mb-4">
                            <div className="product-img">
                              <img
                                className="rounded-md w-full "
                                src={`${API_FILE_URL}assets/images/product/${product.productImage}`}
                                alt={product.productTitle}
                              />
                            </div>
                            <p className=" border-2"></p>
                            <div className="product-info h-auto">
                              <div className="product-badge">
                                <p className="font-bold text-xl text-[#ae8c2ff5]">
                                  {product.productTitle}
                                </p>
                              </div>
                              <h2 className="product-title  go-top">
                                <p className="text-sm font-normal">
                                  {product.productDescription}
                                </p>
                              </h2>

                              <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                <li>
                                  <span className="">Kg/1 m :</span>{" "}
                                  {product.productDetail}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridV1;
